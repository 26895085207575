var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mouchak-pdf-tools" },
    [
      _c("div", { staticClass: "mouchak-tools-text" }, [_vm._v("Tools")]),
      _c(
        "v-btn",
        {
          attrs: { text: "", icon: "" },
          on: {
            click: function ($event) {
              return _vm.updateMode("pointer")
            },
          },
        },
        [
          _c(
            "v-icon",
            {
              attrs: {
                size: "30",
                color: _vm.selectedMode == "pointer" ? "primary" : "gray",
              },
            },
            [_vm._v("fa-mouse-pointer")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "line mt-3" }),
      _c(
        "div",
        [
          _c(
            "v-icon",
            {
              staticClass: "py-3 px-1",
              attrs: {
                size: "30",
                color: _vm.selectedMode == "pencil" ? "primary" : "gray",
              },
              on: {
                click: function ($event) {
                  return _vm.updateMode("pencil")
                },
              },
            },
            [_vm._v("mdi-pencil")]
          ),
          _c(
            "select",
            {
              staticStyle: {
                border: "1px solid #0099dc",
                "border-radius": "5px",
              },
              attrs: { disabled: _vm.selectedMode != "pencil" },
              on: {
                input: (e) => _vm.$emit("thickness-change", e.target.value),
              },
            },
            [
              _c("option", { attrs: { value: "1", selected: "" } }, [
                _vm._v("1px"),
              ]),
              _c("option", { attrs: { value: "4" } }, [_vm._v("4px")]),
              _c("option", { attrs: { value: "8" } }, [_vm._v("8px")]),
              _c("option", { attrs: { value: "12" } }, [_vm._v("12px")]),
              _c("option", { attrs: { value: "16" } }, [_vm._v("16px")]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "color-container py-3" }, [
        _c(
          "div",
          {
            class: ["color-block red", { inert: _vm.selectedMode != "pencil" }],
            on: {
              click: function ($event) {
                return _vm.updateColor("red")
              },
            },
          },
          [
            _vm.selectedColor == "red"
              ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v("checked")])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            class: [
              "color-block green",
              { inert: _vm.selectedMode != "pencil" },
            ],
            on: {
              click: function ($event) {
                return _vm.updateColor("green")
              },
            },
          },
          [
            _vm.selectedColor == "green"
              ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v("checked")])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            class: [
              "color-block black",
              { inert: _vm.selectedMode != "pencil" },
            ],
            on: {
              click: function ($event) {
                return _vm.updateColor("black")
              },
            },
          },
          [
            _vm.selectedColor == "black"
              ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v("checked")])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "line" }),
      _c(
        "v-icon",
        {
          staticClass: "py-5",
          attrs: { color: _vm.selectedMode == "eraser" ? "primary" : "gray" },
          on: {
            click: function ($event) {
              return _vm.updateMode("eraser")
            },
          },
        },
        [_vm._v("mdi-eraser")]
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        [
          _c(
            "v-icon",
            {
              staticClass: "py-5 px-2",
              attrs: { color: _vm.selectedMode == "text" ? "primary" : "gray" },
              on: {
                click: function ($event) {
                  return _vm.updateMode("text")
                },
              },
            },
            [_vm._v("mdi-format-text")]
          ),
          _c(
            "select",
            {
              staticStyle: {
                border: "1px solid #0099DC",
                "border-radius": "5px",
              },
              attrs: { disabled: _vm.selectedMode != "text" },
              on: {
                input: (e) => _vm.$emit("font-size-change", e.target.value),
              },
            },
            [
              _c("option", { attrs: { value: "12" } }, [_vm._v("12px")]),
              _c("option", { attrs: { value: "15" } }, [_vm._v("15px")]),
              _c("option", { attrs: { value: "18" } }, [_vm._v("18px")]),
              _c("option", { attrs: { value: "25" } }, [_vm._v("25px")]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "color-container py-1" }, [
        _c(
          "div",
          {
            class: ["color-block red", { inert: _vm.selectedMode != "text" }],
            on: {
              click: function ($event) {
                return _vm.updateTextColor("red")
              },
            },
          },
          [
            _vm.selectedTextColor == "red"
              ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v("checked")])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            class: ["color-block green", { inert: _vm.selectedMode != "text" }],
            on: {
              click: function ($event) {
                return _vm.updateTextColor("green")
              },
            },
          },
          [
            _vm.selectedTextColor == "green"
              ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v("checked")])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            class: ["color-block black", { inert: _vm.selectedMode != "text" }],
            on: {
              click: function ($event) {
                return _vm.updateTextColor("black")
              },
            },
          },
          [
            _vm.selectedTextColor == "black"
              ? _c("v-icon", { attrs: { color: "white" } }, [_vm._v("checked")])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }