<template>
  <div style="display: flex">
    <pdf-tools
      v-if="asses"
      @color-change="onColorChange"
      @mode-change="onModeChange"
      @thickness-change="onThicknessChange"
      @text-color-change="onTextColorChange"
      @font-size-change="onFontChange"
    ></pdf-tools>
    <div
      ref="canvasContainer"
      class="mouchak-canvas-container"
      :style="asses ? 'width: calc(100%-132px);' : 'width: 100%;'"
      @scroll="onScroll"
    >
      <div class="mouchak-canvas-header">
        {{ currentPage }} / {{ pageNums }}
      </div>
      <canvas
        v-for="(page, index) in pages"
        :key="index"
        :ref="'page-' + (index + 1)"
        style="padding: 10px"
        @mousedown="e => onMouseDown(index + 1, e)"
        @mouseup="onMouseUp"
        @mousemove="e => draw(index + 1, e)"
        @click="e => addText(index + 1, e)"
      ></canvas>
    </div>
  </div>
</template>

<script>
import pdfService from "#ecf/assessment/services/api";
import PdfTools from "#ecf/assessment/components/PdfTools";
import { saveAssesment } from "#ecf/assessment/services/worker-api";
export default {
  name: "PDFRenderer",
  components: { PdfTools },
  props: {
    url: { type: String, required: true },
    asses: { type: Boolean, default: false },
    save: { type: Boolean, default: false },
    s3Key: { type: String, required: true }
  },
  data() {
    return {
      pageNums: -1,
      currentPage: -1,
      pages: [],
      pageHeight: undefined,
      mode: "pointer",
      thickness: 1,
      color: "black",
      textColor: "black",
      fontSize: 12,
      drawing: false,
      dotflag: false,
      prevx: 0,
      prevy: 0,
      curx: 0,
      cury: 0,
      hasInput: false
    };
  },
  watch: {
    asses(value) {
      if (value === false) {
        this.getDocument();
      }
    },
    async save(value) {
      if (value === true) {
        let arr = [...Array(this.pageNums).keys()];
        let scale = this.$vuetify.breakpoint.lgAndDown ? 0.83 : 0.66;
        let canvases = arr.map(index =>
          this.$refs["page-" + (index + 1)][0].toDataURL()
        );
        let file = await saveAssesment(canvases, scale);
        await pdfService.save(new File([file], "temp.pdf"), this.s3Key);
        this.$emit("save-done");
      }
    }
  },
  mounted() {
    this.getDocument();
  },
  methods: {
    async getDocument() {
      try {
        let pdfdoc = await window.pdfjsLib.getDocument(this.url).promise;
        this.pageNums = pdfdoc.numPages;
        this.pages = [...Array(this.pageNums).keys()];
        this.currentPage = 1;
        for (let i = 1; i <= this.pageNums; i++) {
          await this.renderPage(i, pdfdoc);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async renderPage(index, pdfdoc) {
      let page = await pdfdoc.getPage(index);
      let viewport = page.getViewport({
        scale: this.$vuetify.breakpoint.lgAndDown ? 1.2 : 1.75
      });
      let canvas = this.$refs["page-" + index];
      canvas[0].height = viewport.height;
      canvas[0].width = viewport.width;
      this.pageHeight = viewport.height;
      let ctx = canvas[0].getContext("2d");
      await page.render({ canvasContext: ctx, viewport });
    },
    onScroll() {
      let scrollTop = this.$refs.canvasContainer.scrollTop;
      let pagePassed = scrollTop / this.pageHeight;
      this.currentPage = Math.floor(pagePassed + 0.3) + 1;
      if (this.currentPage > this.pageNums) this.currentPage = this.pageNums;
    },
    onColorChange(color) {
      this.color = color;
    },
    onModeChange(mode) {
      this.mode = mode;
    },
    onThicknessChange(thickness) {
      this.thickness = thickness;
    },
    onTextColorChange(color) {
      this.textColor = color;
    },
    onFontChange(size) {
      this.fontSize = size;
    },
    onMouseDown(index, event) {
      let canvas = this.$refs["page-" + index][0];
      let ctx = canvas.getContext("2d");
      let rect = canvas.getBoundingClientRect();
      this.prevx = this.curx;
      this.prevy = this.cury;
      this.curx = event.clientX - rect.left - 10;
      this.cury = event.clientY - rect.top + (this.mode == "pencil" ? 30 : 10);
      this.drawing = true;
      if (["pencil", "eraser"].includes(this.mode) && this.asses) {
        this.dotflag = true;
        if (this.dotflag) {
          ctx.beginPath();
          ctx.fillStyle = this.mode == "pencil" ? this.color : "white";
          let thick = this.mode == "pencil" ? this.thickness : 10;
          ctx.fillRect(this.curx, this.cury, thick, thick);
          ctx.closePath();
          this.dotflag = false;
        }
      }
    },
    onMouseUp() {
      this.drawing = false;
    },
    draw(canvasNumber, event) {
      const canvas = this.$refs["page-" + canvasNumber][0];
      let rect = canvas.getBoundingClientRect();
      if (this.mode == "eraser" && this.asses) {
        canvas.style.cursor = `url(${require("#ecf/assessment/assets/eraser.svg")}), auto`;
      } else if (this.mode == "pencil" && this.asses) {
        canvas.style.cursor = `url(${require("#ecf/assessment/assets/Pencil.svg")}), auto`;
      } else {
        canvas.style.cursor = "default";
      }
      if (
        ["pencil", "eraser"].includes(this.mode) &&
        this.drawing &&
        this.asses
      ) {
        const ctx = canvas.getContext("2d");
        this.prevx = this.curx;
        this.prevy = this.cury;
        this.curx = event.clientX - rect.left - 10;
        this.cury =
          event.clientY - rect.top + (this.mode == "pencil" ? 30 : 10);
        ctx.beginPath();
        ctx.lineWidth = this.mode == "pencil" ? this.thickness : 10;
        ctx.lineCap = "round";
        ctx.strokeStyle = this.mode == "pencil" ? this.color : "white";
        ctx.moveTo(this.prevx, this.prevy);
        ctx.lineTo(this.curx, this.cury);
        ctx.stroke();
      }
    },
    addText(index, event) {
      if (this.mode != "text") return;
      if (this.hasInput) return;
      if (!this.asses) return;
      const canvas = this.$refs["page-" + index][0];
      let rect = canvas.getBoundingClientRect();
      let x = event.clientX - rect.left;
      let y = event.clientY - rect.top;
      this.addInput(canvas, x, y, index);
    },
    addInput(canvas, x, y, index) {
      let input = document.createElement("input");
      input.type = "text";
      input.placeholder = "Write here something";
      input.style.position = "absolute";
      let left = (canvas.offsetWidth - canvas.width) / 2 + x;
      input.style.left = left + "px";
      let top = (index - 1) * (this.pageHeight + 20) + y;
      input.style.top = top + "px";
      input.style.border = "1px solid";
      input.style.padding = "5px";
      input.style.background = "white";
      input.onkeydown = e => {
        if (e.key === "Enter") {
          this.drawText(canvas, input.value, x, y);
          canvas.parentElement.removeChild(input);
          this.hasInput = false;
        }
      };
      input.onblur = () => {
        canvas.parentElement.removeChild(input);
        this.hasInput = false;
      };
      canvas.parentElement.appendChild(input);
      input.focus();
      this.hasInput = true;
    },
    drawText(canvas, text, x, y) {
      let ctx = canvas.getContext("2d");
      ctx.textBaseAlign = "top";
      ctx.textAlign = "left";
      ctx.fillStyle = this.textColor;
      ctx.font = `${this.fontSize}px sans-serif`;
      ctx.fillText(text, x, y);
    }
  }
};
</script>

<style scoped>
.mouchak-canvas-container {
  height: 75vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #525659;
  border-radius: 10px;
  position: relative;
}
.mouchak-canvas-header {
  padding: 10px;
  background: #323639;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
}
</style>
