import { Backend } from "~/utils/backend";
import { checkStatus } from "/global/utils/helpers";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);

export default {
  save(file, s3_key) {
    return $backend
      .post("teachers_save_assessed", { s3_key })
      .then(checkStatus)
      .then(r => $backend.upload_to_s3(r.data.content.upload_info, file));
  }
};
