var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _vm.asses
        ? _c("pdf-tools", {
            on: {
              "color-change": _vm.onColorChange,
              "mode-change": _vm.onModeChange,
              "thickness-change": _vm.onThicknessChange,
              "text-color-change": _vm.onTextColorChange,
              "font-size-change": _vm.onFontChange,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          ref: "canvasContainer",
          staticClass: "mouchak-canvas-container",
          style: _vm.asses ? "width: calc(100%-132px);" : "width: 100%;",
          on: { scroll: _vm.onScroll },
        },
        [
          _c("div", { staticClass: "mouchak-canvas-header" }, [
            _vm._v(
              " " + _vm._s(_vm.currentPage) + " / " + _vm._s(_vm.pageNums) + " "
            ),
          ]),
          _vm._l(_vm.pages, function (page, index) {
            return _c("canvas", {
              key: index,
              ref: "page-" + (index + 1),
              refInFor: true,
              staticStyle: { padding: "10px" },
              on: {
                mousedown: (e) => _vm.onMouseDown(index + 1, e),
                mouseup: _vm.onMouseUp,
                mousemove: (e) => _vm.draw(index + 1, e),
                click: (e) => _vm.addText(index + 1, e),
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }