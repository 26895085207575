<template>
  <div class="mouchak-pdf-tools">
    <div class="mouchak-tools-text">Tools</div>
    <v-btn text icon @click="updateMode('pointer')">
      <v-icon size="30" :color="selectedMode == 'pointer' ? 'primary' : 'gray'"
        >fa-mouse-pointer</v-icon
      ></v-btn
    >
    <div class="line mt-3"></div>
    <div>
      <v-icon
        size="30"
        class="py-3 px-1"
        :color="selectedMode == 'pencil' ? 'primary' : 'gray'"
        @click="updateMode('pencil')"
        >mdi-pencil</v-icon
      >
      <select
        style="border: 1px solid #0099dc; border-radius: 5px;"
        :disabled="selectedMode != 'pencil'"
        @input="e => $emit('thickness-change', e.target.value)"
      >
        <option value="1" selected>1px</option>
        <option value="4">4px</option>
        <option value="8">8px</option>
        <option value="12">12px</option>
        <option value="16">16px</option>
      </select>
    </div>
    <div class="color-container py-3">
      <div
        :class="['color-block red', { inert: selectedMode != 'pencil' }]"
        @click="updateColor('red')"
      >
        <v-icon v-if="selectedColor == 'red'" color="white">checked</v-icon>
      </div>
      <div
        :class="['color-block green', { inert: selectedMode != 'pencil' }]"
        @click="updateColor('green')"
      >
        <v-icon v-if="selectedColor == 'green'" color="white">checked</v-icon>
      </div>
      <div
        :class="['color-block black', { inert: selectedMode != 'pencil' }]"
        @click="updateColor('black')"
      >
        <v-icon v-if="selectedColor == 'black'" color="white">checked</v-icon>
      </div>
    </div>
    <div class="line"></div>
    <v-icon
      class="py-5"
      :color="selectedMode == 'eraser' ? 'primary' : 'gray'"
      @click="updateMode('eraser')"
      >mdi-eraser</v-icon
    >
    <div class="line"></div>
    <div>
      <v-icon
        class="py-5 px-2"
        :color="selectedMode == 'text' ? 'primary' : 'gray'"
        @click="updateMode('text')"
        >mdi-format-text</v-icon
      >
      <select
        style="border: 1px solid #0099DC; border-radius: 5px;"
        :disabled="selectedMode != 'text'"
        @input="e => $emit('font-size-change', e.target.value)"
      >
        <option value="12">12px</option>
        <option value="15">15px</option>
        <option value="18">18px</option>
        <option value="25">25px</option>
      </select>
    </div>
    <div class="color-container py-1">
      <div
        :class="['color-block red', { inert: selectedMode != 'text' }]"
        @click="updateTextColor('red')"
      >
        <v-icon v-if="selectedTextColor == 'red'" color="white">checked</v-icon>
      </div>
      <div
        :class="['color-block green', { inert: selectedMode != 'text' }]"
        @click="updateTextColor('green')"
      >
        <v-icon v-if="selectedTextColor == 'green'" color="white"
          >checked</v-icon
        >
      </div>
      <div
        :class="['color-block black', { inert: selectedMode != 'text' }]"
        @click="updateTextColor('black')"
      >
        <v-icon v-if="selectedTextColor == 'black'" color="white"
          >checked</v-icon
        >
      </div>
    </div>
    <!-- <div class="line"></div>
    <v-icon
      class="py-5"
      :color="selectedMode == 'comment' ? 'primary' : 'gray'"
      @click="updateMode('comment')"
      >mdi-comment-text</v-icon
    > -->
  </div>
</template>

<script>
export default {
  name: "PdfTools",
  data() {
    return {
      selectedColor: "black",
      selectedTextColor: "black",
      selectedMode: "pointer"
    };
  },
  mounted() {
    this.updateMode("pointer");
    this.updateColor("black");
    this.updateTextColor("black");
    this.$emit("thickness-change", 1);
  },
  methods: {
    updateMode(mode) {
      this.selectedMode = mode;
      this.$emit("mode-change", mode);
    },
    updateColor(color) {
      this.selectedColor = color;
      this.$emit("color-change", color);
    },
    updateTextColor(color) {
      this.selectedTextColor = color;
      this.$emit("text-color-change", color);
    }
  }
};
</script>

<style scoped>
.mouchak-pdf-tools {
  width: 132px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 0.5px solid #0099dc;
}
.mouchak-tools-text {
  font-size: 20px;
  padding: 16px;
}
.line {
  height: 1px;
  width: 100%;
  background: #0099dc;
}
.color-container {
  display: flex;
  justify-content: space-around;
  width: 90%;
}
.color-block {
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 12px;
  padding: 2px;
  cursor: pointer;
}
.red {
  background-color: #e80000;
}
.green {
  background-color: #006d1d;
}
.black {
  background-color: black;
}
.inert {
  visibility: hidden;
  pointer-events: none;
}
</style>
